export const ROUTE_METADATA_KEY = {
  MODULE_NAME: 'moduleName',
  PERMISSION: 'permission',
  PAGE_HEADER_INFO: 'pageHeader',
} as const;

export type RouteMetadataKey =
  | typeof ROUTE_METADATA_KEY.MODULE_NAME
  | typeof ROUTE_METADATA_KEY.PERMISSION
  | typeof ROUTE_METADATA_KEY.PAGE_HEADER_INFO;
